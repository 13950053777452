<template>
  <div class="content-wrapper">
    <div class="content-box">
      <el-form
        ref="invitationForm"
        :model="user"
        :rules="rules"
        @keyup.enter.native="checkForm"
      >
        <h1>Einladung annehmen</h1>

        <el-form-item label="E-Mail-Adresse" prop="email">
          <el-input
            v-model="user.email"
            type="email"
            :disabled="true"
          ></el-input>
        </el-form-item>

        <el-form-item label="Passwort wählen" prop="password">
          <el-input v-model="user.password" type="password"></el-input>
        </el-form-item>

        <el-form-item label="Passwort wiederholen" prop="passwordConfirmation">
          <el-input
            v-model="user.passwordConfirmation"
            type="password"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            class="fullwidth-button"
            :loading="loading"
            @click="checkForm"
          >
            Einrichtung abschließen
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import utils from "@/utils"
import UserRepository from "@/repositories/user_repository.js"
import FormMixin from "@/mixins/form.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  metaInfo: {
    title: "Einladung annehmen"
  },
  mixins: [FormMixin, ApiErrorHandlerMixin],
  data() {
    return {
      user: {
        email: this.$route.query.email,
        token: this.$route.query.token
      },
      rules: {
        password: [
          {
            required: true,
            message: "Bitte Passwort angeben",
            trigger: "blur"
          },
          {
            min: 10,
            message: "Das Passwort muss mind. 10 Zeichen haben",
            trigger: "change"
          }
        ],
        passwordConfirmation: [
          {
            required: true,
            message: "Bitte Passwort wiederholen",
            trigger: "blur"
          },
          { validator: this.validatePasswords, trigger: "blur" }
        ]
      },
      loading: false
    }
  },
  computed: {
    ...mapGetters("auth", ["userData"])
  },
  methods: {
    validatePasswords(rule, value, callback) {
      if (value !== this.user.password) {
        callback(new Error("Die Passwörter stimmen nicht überein"))
      } else {
        callback()
      }
    },
    checkForm() {
      this.$refs["invitationForm"].validate(valid => {
        if (valid) {
          this.submitForm()
        } else {
          this.showFormErrors()
        }
      })
    },
    async submitForm() {
      this.loading = true
      try {
        let data = await UserRepository.acceptInvitation(this.user)
        this.loading = false
        this.$store.commit("auth/login", data)
        this.$router.push({ name: "DashboardPage" })
        utils.addErrorBreadcrumb({
          category: "Auth", // e.g. "UI", "Network", "Navigation", "Console"
          action: "Accepted invitation" // e.g "The user clicked a button", "HTTP 500 from http://blablabla.com"
        })
      } catch (error) {
        this.loading = false
        if (error.response && error.response.status === 422) {
          this.$message({
            showClose: true,
            message: "Einladungscode ungültig",
            type: "error",
            duration: 10000
          })
          utils.trackError("InvitationError", "Einladungscode ungültig")
        } else {
          this.handleApiError(error, "Fehler Einladung")
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content-wrapper {
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
}

.content-box {
  width: 400px;
  box-shadow: 0 0 25px #cac6c6;
  border-radius: 5px;
  padding: 25px;
}

h1 {
  text-align: center;
}

.fullwidth-button {
  width: 100%;
}
</style>
